<template>
    <div>
        <Navbar page="Assinatura atual" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h1 class="font-r font-medium text-3xl mb-8">Cookie Banner da Fácil LGPD</h1>
        </div>

        <div class="bg-white">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div class="mx-auto max-w-4xl sm:text-center">
                    <h2 class="text-base font-semibold leading-7 text-terciary">Nossos planos</h2>
                    <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Escolha o plano deseja</p>
                </div>
                <p class="mx-auto mt-6 max-w-lg text-lg leading-8 text-gray-600 sm:text-center">Na Fácil LGPD você tem liberdade de escolher o melhor plano para você a qualquer momento</p>
                <div class="mt-20 flow-root max-w-2xl mx-auto">
                    <div class="space-x-2 grid grid-cols-2 gap-16 divide-y divide-gray-100">

                        <div v-for="price in prices" :key="price.id" :id="price.lookup_key" :class="{'ring-4 ring-green-500': price.id === subscription.plan.id}" class="pt-8 lg:px-8 xl:px-14 shadow-md rounded-md py-5 px-10 bg-gray-50" >
                            <h3 id="tier-basic" class="text-base font-semibold leading-7 text-gray-900">{{price.nickname}}</h3>
                            <p class="mt-6 flex items-baseline gap-x-1">
                                <span class="text-5xl font-bold tracking-tight text-gray-900">R$ {{price.unit_amount / 100}}</span>
                                <span class="text-sm font-semibold leading-6 text-gray-600">/mês</span>
                            </p>
                            <p class="mt-3 text-sm leading-6 text-gray-500">{{ price.metadata.label }}</p>
                            <a v-if="price.id !== subscription.plan.id && price.unit_amount" :href="`/stripe/subscribe/${price.id}`" aria-describedby="tier-basic"
                                class="mt-10 block rounded-md bg-terciary px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-terciary">
                                Assinar</a>

                            <button v-if="price.id !== subscription.plan.id && !price.unit_amount && subscription.plan.id !== 'FREE'" @click="downgrade()" type="button" class="mt-10 block rounded-md bg-terciary px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-terciary">Fazer Downgrade</button>
                            
                            <span v-if="price.id === subscription.plan.id || (!price.unit_amount && subscription.plan.id === 'FREE')"  aria-describedby="tier-basic"
                                class="mt-10 block rounded-md bg-green-500 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-terciary">
                                Seu plano atual</span>
                            <p class="mt-10 text-sm font-semibold leading-6 text-gray-900">
                                <span v-if="!price.unit_amount" >Tudo que você precisa para começar.</span>
                                <span v-if="price.unit_amount" >Tudo do básico +</span>
                            </p>

                            <ul v-if="!price.unit_amount" role="list" class="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                                <li class="flex gap-x-3">
                                    <svg class="h-6 w-5 flex-none text-terciary" viewBox="0 0 20 20"
                                        fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Apenas 1 site
                                </li>
                                <li class="flex gap-x-3">
                                    <svg class="h-6 w-5 flex-none text-terciary" viewBox="0 0 20 20"
                                        fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Acesso completo
                                </li>
                                <li class="flex gap-x-3">
                                    <svg class="h-6 w-5 flex-none text-terciary" viewBox="0 0 20 20"
                                        fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Customização básica
                                </li>
                                <li class="flex gap-x-3">
                                    <svg class="h-6 w-5 flex-none text-terciary" viewBox="0 0 20 20"
                                        fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Relatórios essenciais
                                </li>
                            </ul>

                            <ul v-if="price.unit_amount" role="list" class="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                                <li class="flex gap-x-3">
                                    <svg class="h-6 w-5 flex-none text-terciary" viewBox="0 0 20 20"
                                        fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Até 10 sites
                                </li>
                                <li class="flex gap-x-3">
                                    <svg class="h-6 w-5 flex-none text-terciary" viewBox="0 0 20 20"
                                        fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Scanner automático
                                </li>
                                <li class="flex gap-x-3">
                                    <svg class="h-6 w-5 flex-none text-terciary" viewBox="0 0 20 20"
                                        fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    100% customizável
                                </li>
                                <li class="flex gap-x-3">
                                    <svg class="h-6 w-5 flex-none text-terciary" viewBox="0 0 20 20"
                                        fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    Relatórios completos
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        data() {
            return {
                route: 'stripe',
                user: null,

                prices: [],
                subscription: {
                    status: '',
                    plan: {
                        id: '',
                    },
                }
            }
        },
        methods: {
            async start() {
                const listReq = await this.$http.get(`/v1/${this.route}/config`);
                
                this.prices = listReq.data.prices;
                this.subscription = listReq.data.subscription;

                const userReq = await this.$http.post('/v1/users/me');
                this.user = userReq.data;
            },

            async downgrade() {
                const reqDown = await this.$http.put(`/v1/${this.route}/downgrade`);

                if (reqDown.data.success) {
                    window.location.reload()
                }
            }
        },

        async beforeMount() {
            this.start();
        }
    }
</script>